<template>
  <v-container
    class="error-container pa-8 d-flex align-center flex-column justify-center gr-5"
    fluid
  >
    <h1>{{ error?.statusCode }}</h1>
    <h2>{{ error?.message }}</h2>
    <NuxtLink
      to="/"
      @click="handleError"
    >
      Go back home
    </NuxtLink>
  </v-container>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app';

defineProps({
  error: Object as () => NuxtError
});

const handleError = () => clearError();
</script>

<style lang="scss" scoped>
.error-container {
  min-height: 100vh;
}
</style>
