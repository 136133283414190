import '@mdi/font/css/materialdesignicons.css';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { myCustomLightTheme } from '~/utils/themes';

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme
      }
    },
    defaults: {
      global: {
        font: {
          family: 'Plus Jakarta Sans, sans-serif'
        }
      },
      VDialog: {
        VCard: {
          style: { 'border-radius': '12px' }
        }
      }
    }
  });
  app.vueApp.use(vuetify);
});
