import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const { public: { sentry }, isDev } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: isDev ? 'development' : 'production',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    beforeSend(event, hint) {
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error('[Exception handled by Sentry]:', {
          event,
          hint
        });
      }
      return event;
    }
  });
});
