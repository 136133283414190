import { default as compare_45with_45liveuMf7SansFlMeta } from "/app/pages/[storeId]/compare-with-live.vue?macro=true";
import { default as indexVHio02A5wfMeta } from "/app/pages/[storeId]/index.vue?macro=true";
import { default as step_45_91type_93VeDkq3InaRMeta } from "/app/pages/[storeId]/step-[type].vue?macro=true";
import { default as health_45checkePC2yKKeYyMeta } from "/app/pages/health-check.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91token_93DOy7Epqp0dMeta } from "/app/pages/password-recovery/[token].vue?macro=true";
import { default as requesthCp5DSjIhKMeta } from "/app/pages/password-recovery/request.vue?macro=true";
import { default as redirectvHLxODTt5rMeta } from "/app/pages/redirect.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
export default [
  {
    name: compare_45with_45liveuMf7SansFlMeta?.name ?? "storeId-compare-with-live",
    path: compare_45with_45liveuMf7SansFlMeta?.path ?? "/:storeId()/compare-with-live",
    meta: compare_45with_45liveuMf7SansFlMeta || {},
    alias: compare_45with_45liveuMf7SansFlMeta?.alias || [],
    redirect: compare_45with_45liveuMf7SansFlMeta?.redirect,
    component: () => import("/app/pages/[storeId]/compare-with-live.vue").then(m => m.default || m)
  },
  {
    name: indexVHio02A5wfMeta?.name ?? "storeId",
    path: indexVHio02A5wfMeta?.path ?? "/:storeId()",
    meta: indexVHio02A5wfMeta || {},
    alias: indexVHio02A5wfMeta?.alias || [],
    redirect: indexVHio02A5wfMeta?.redirect,
    component: () => import("/app/pages/[storeId]/index.vue").then(m => m.default || m)
  },
  {
    name: step_45_91type_93VeDkq3InaRMeta?.name ?? "storeId-step-type",
    path: step_45_91type_93VeDkq3InaRMeta?.path ?? "/:storeId()/step-:type()",
    meta: step_45_91type_93VeDkq3InaRMeta || {},
    alias: step_45_91type_93VeDkq3InaRMeta?.alias || [],
    redirect: step_45_91type_93VeDkq3InaRMeta?.redirect,
    component: () => import("/app/pages/[storeId]/step-[type].vue").then(m => m.default || m)
  },
  {
    name: health_45checkePC2yKKeYyMeta?.name ?? "health-check",
    path: health_45checkePC2yKKeYyMeta?.path ?? "/health-check",
    meta: health_45checkePC2yKKeYyMeta || {},
    alias: health_45checkePC2yKKeYyMeta?.alias || [],
    redirect: health_45checkePC2yKKeYyMeta?.redirect,
    component: () => import("/app/pages/health-check.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93DOy7Epqp0dMeta?.name ?? "password-recovery-token",
    path: _91token_93DOy7Epqp0dMeta?.path ?? "/password-recovery/:token()",
    meta: _91token_93DOy7Epqp0dMeta || {},
    alias: _91token_93DOy7Epqp0dMeta?.alias || [],
    redirect: _91token_93DOy7Epqp0dMeta?.redirect,
    component: () => import("/app/pages/password-recovery/[token].vue").then(m => m.default || m)
  },
  {
    name: requesthCp5DSjIhKMeta?.name ?? "password-recovery-request",
    path: requesthCp5DSjIhKMeta?.path ?? "/password-recovery/request",
    meta: requesthCp5DSjIhKMeta || {},
    alias: requesthCp5DSjIhKMeta?.alias || [],
    redirect: requesthCp5DSjIhKMeta?.redirect,
    component: () => import("/app/pages/password-recovery/request.vue").then(m => m.default || m)
  },
  {
    name: redirectvHLxODTt5rMeta?.name ?? "redirect",
    path: redirectvHLxODTt5rMeta?.path ?? "/redirect",
    meta: redirectvHLxODTt5rMeta || {},
    alias: redirectvHLxODTt5rMeta?.alias || [],
    redirect: redirectvHLxODTt5rMeta?.redirect,
    component: () => import("/app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test",
    path: testL3AVDvhyXeMeta?.path ?? "/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  }
]