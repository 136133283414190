import { APOLLO_TOKEN } from '~/constants';

export default defineNuxtRouteMiddleware((to) => {
  const excludePath = ['health-check', 'register', 'login', 'password-recovery-token', 'password-recovery-request'];
  if (typeof to.name === 'string' && excludePath.includes(to.name)) {
    return;
  }
  const tokenCookie = useCookie(APOLLO_TOKEN);
  if (!tokenCookie.value) {
    return navigateTo('/login');
  }
});
