import type { ErrorResponse } from '@nuxtjs/apollo';
import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  // Nuxt Apollo error hook
  nuxtApp.hook('apollo:error', (error: ErrorResponse) => {
    if ('networkError' in error && error.networkError && 'statusCode' in error.networkError) {
      const statusCode = error.networkError.statusCode;
      if (statusCode === 403) {
        // eslint-disable-next-line no-console
        console.log(
          '%c' + 'STATUS_CODE: ' + error.networkError.statusCode,
          'color: black; background-color: #BADA55; padding: 2px 5px; border-radius: 2px; font-weight:bold;'
        );
        navigateTo('/login');
      }
    }
    if (error?.graphQLErrors?.length) {
      Sentry.captureException(error?.graphQLErrors, {
        level: 'error',
        extra: {
          operationName: error.operation.operationName,
          variables: error.operation.variables,
          message: error?.graphQLErrors?.[0]?.message,
          currentPath: nuxtApp?._route?.fullPath ?? '-'
        }
      });
    }
    if (error?.networkError) {
      Sentry.captureException(error?.networkError, {
        level: 'error',
        extra: {
          operationName: error.operation.operationName,
          variables: error.operation.variables,
          message: error?.networkError?.message,
          currentPath: nuxtApp?._route?.fullPath ?? '-'
        }
      });
    }
    if (process.env.NODE_ENV === 'production') return;
    // eslint-disable-next-line no-console
    console.log('Apollo Error Handler', error);
    // eslint-disable-next-line no-console
    console.log(
      '%c' + 'OPERATION_NAME: ' + error.operation.operationName,
      'color: black; background-color: #BADA55; padding: 2px 5px; border-radius: 2px; font-weight:bold;'
    );
    // eslint-disable-next-line no-console
    console.log('variables', error.operation.variables);
    if (error?.graphQLErrors) {
      // eslint-disable-next-line no-console
      console.log(
        '%c' + 'ERROR MESSAGE: ' + error?.graphQLErrors[0]?.message,
        'color: black; background-color: #BADA55; padding: 2px 5px; border-radius: 2px; font-weight:bold;'
      );
    }
  });
});
